import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";

// image.attributes.img.data[0].attributes.formats.small.url

const IMAGES = gql`
  query GetImages {
    images(pagination: { limit: 1000 }) {
      data {
        id
        attributes {
          txt
          camera
          img(pagination: { limit: 1000 }) {
            data {
              attributes {
                name
                alternativeText
                formats
              }
            }
          }
        }
      }
    }
  }
`;

const HEADERIMAGE = gql`
  query GetHeaderImage {
    headerImage {
      data {
        id
        attributes {
          headerImg {
            data {
              attributes {
                alternativeText
                formats
              }
            }
          }
        }
      }
    }
  }
`;

const ABOUT = gql`
  query GetAbout {
    about {
      data {
        id
        attributes {
          aboutBody
          aboutImg {
            data {
              id
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`;

const ACCENT = gql`
  query GetAccentImg {
    accentImage {
      data {
        id
        attributes {
          img {
            data {
              attributes {
                alternativeText
                formats
              }
            }
          }
        }
      }
    }
  }
`;

let setClickedId = 0;

function HomePage() {
  const [toggleAlbum, setToggleAlbum] = useState(false);
  const [fade, setFade] = useState(false);

  // hooks for gql requests
  const { loading, error, data } = useQuery(IMAGES);
  const {
    loading: loadingAbout,
    error: errorAbout,
    data: dataAbout,
  } = useQuery(ABOUT);
  const {
    loading: loadingHeaderImg,
    error: errorHeaderImg,
    data: dataHeaderImg,
  } = useQuery(HEADERIMAGE);
  const {
    loading: loadingAccentImg,
    error: errorAccentImg,
    data: dataAccentImg,
  } = useQuery(ACCENT);

  // error/loading on gql requests
  if (loading || loadingAbout || loadingHeaderImg || loadingAccentImg) {
    return <p>loading...</p>;
  }
  if (error || errorAbout || errorHeaderImg || errorAccentImg) {
    // console.log(error);
    return <p>error -_-</p>;
  }

  const triggerToggleAbout = () => {
    setFade((prevState) => {
      return !prevState;
    });
  };

  const triggerToggleAlbum = (imageId) => {
    const carouselContainers = document.querySelectorAll(".carousel-container");
    carouselContainers.forEach((container) => {
      if (container.getAttribute("data-key") === imageId) {
        container.style.display = "block";
      } else {
        container.style.display = "none";
      }
    });
    setClickedId = imageId;
    setToggleAlbum((prevState) => {
      return !prevState;
    });
  };

  // TODO: apply thumbnail index to albumIndex when clicked
  const goClickedImage = (event) => {
    let currentMainPhoto = document.querySelector(".albumPhotoMain");
    const mainPhotoWrapper = document.querySelector(".albumPhotoCard");
    const allThumbnailWrappers = document.querySelectorAll(
      ".carousel-container"
    );
    // make current main photo have thumbnail classes and append it to thumbnail wrapper
    currentMainPhoto.classList.remove("img-fluid"); // bootstrap class
    currentMainPhoto.classList.add("thumbnail");
    allThumbnailWrappers.forEach((wrapper) => {
      // if wrapper has any children at all - in other words if it is the visible one we want to append to
      if (wrapper.firstChild) {
        // wrapper.appendChild(currentMainPhoto);
      }
    });
    currentMainPhoto.classList.remove("albumPhotoMain");
    // make clicked photo have main photo classes and append it to mainPhotoWrapper
    const clonedClickedThumbnail = event.target.cloneNode();
    clonedClickedThumbnail.classList.add("albumPhotoMain");
    clonedClickedThumbnail.classList.add("img-fluid"); // bootstrap class
    clonedClickedThumbnail.classList.remove("thumbnail");
    mainPhotoWrapper.replaceChild(clonedClickedThumbnail, currentMainPhoto);
  };

  // keyboard left/right photo album logic

  let albumIndex = 0;

  window.addEventListener("keydown", (event) => {
    const albumThumbnails = document.querySelectorAll(".thumbnail");
    const albumPhotoMain = document.querySelector(".albumPhotoMain");
    if (event.key === "ArrowRight") {
      if (albumIndex < albumThumbnails.length - 1) {
        albumIndex = albumIndex + 1;
      }
      albumPhotoMain.src = albumThumbnails[albumIndex].src;
    } else if (event.key === "ArrowLeft") {
      if (albumIndex > 0) {
        albumIndex = albumIndex - 1;
        albumPhotoMain.src = albumThumbnails[albumIndex].src;
      }
    }
  });

  const clickedArrowButton = (event) => {
    const albumThumbnails = document.querySelectorAll(".thumbnail");
    const albumPhotoMain = document.querySelector(".albumPhotoMain");
    if (event.target.className === "rightArrow") {
      if (albumIndex < albumThumbnails.length - 1) {
        albumIndex = albumIndex + 1;
      }
      albumPhotoMain.src = albumThumbnails[albumIndex].src;
    } else if (event.target.className === "leftArrow") {
      if (albumIndex > 0) {
        albumIndex = albumIndex - 1;
        albumPhotoMain.src = albumThumbnails[albumIndex].src;
      }
    }
  };

  const clickedCopyEmail = (event) => {
    navigator.clipboard.writeText("intomyskin.net@gmail.com");
    event.target.textContent = "EMAIL COPIED!";
  };

  let headerimgSrc =
    dataHeaderImg.headerImage.data.attributes.headerImg.data.attributes.formats
      .medium.url;

  const clickPrevButton = (imageId) => {
    const carouselContainers = document.querySelectorAll(".carousel-container");
    const innerCarousels = document.querySelectorAll(".carousel-inner");
    const prevButton = document.getElementById("prev-button");
    const nextButton = document.getElementById("next-button");
    const thumbnails = document.querySelectorAll(".thumbnail");

    carouselContainers.forEach((container) => {
      if (container.getAttribute("data-key") === imageId) {
        if (container.style.display === "block") {
          innerCarousels.forEach((inner) => {
            if (inner.getAttribute("data-key") === imageId) {
              const thumbnailsPerView = Math.floor(
                inner.parentElement.offsetWidth / 110
              ); // Assuming each thumbnail + margin is about 110px wide
              if (currentIndex > 0) {
                currentIndex--;
                const offset = -currentIndex * 110; // 110px is the assumed width of thumbnail + margin
                inner.style.transform = `translateX(${offset}px)`;
                prevButton.disabled = currentIndex === 0;
                nextButton.disabled =
                  currentIndex >= thumbnails.length - thumbnailsPerView;
              }
            }
          });
        }
      }
    });
  };

  let currentIndex = 0;

  const clickNextButton = (imageId) => {
    const carouselContainers = document.querySelectorAll(".carousel-container");
    const innerCarousels = document.querySelectorAll(".carousel-inner");
    const prevButton = document.getElementById("prev-button");
    const nextButton = document.getElementById("next-button");
    const thumbnails = document.querySelectorAll(".thumbnail");

    carouselContainers.forEach((container) => {
      if (container.getAttribute("data-key") === imageId) {
        if (container.style.display === "block") {
          innerCarousels.forEach((inner) => {
            if (inner.getAttribute("data-key") === imageId) {
              const thumbnailsPerView = Math.floor(
                inner.parentElement.offsetWidth / 110
              ); // Assuming each thumbnail + margin is about 110px wide
              if (currentIndex < thumbnails.length - thumbnailsPerView) {
                currentIndex++;
                const offset = -currentIndex * 110; // 110px is the assumed width of thumbnail + margin
                inner.style.transform = `translateX(${offset}px)`;
                prevButton.disabled = currentIndex === 0;
                nextButton.disabled =
                  currentIndex >= thumbnails.length - thumbnailsPerView;
              }
              // inner.style.transform = `translateX(300px)`;
            }
          });
        }
      }
    });
  };

  return (
    <div>
      <div className="headerNav">
        <a className="headerNavTitle" href="/">
          HOME
        </a>
        <div className="headerNavPlaylist">
          <a
            href="https://open.spotify.com/playlist/2IcjBMbKgIJwjvtxquotJG"
            target="_blank"
            rel="noopener noreferrer"
          >
            INTOMYSKIN PLAYLIST
          </a>
        </div>
        <div onClick={triggerToggleAbout} className="headerNavAbout">
          ABOUT
        </div>
        <div
          onAnimationEnd={triggerToggleAbout}
          className={fade ? "visibleClass" : "hiddenClass"}
        >
          <button className="closeAboutDivButton" onClick={triggerToggleAbout}>
            x
          </button>
          <div className="aboutDiv">
            <p>
              {dataAbout.about.data.attributes.aboutBody[0].children[0].text}
            </p>
            <img
              src={
                dataAbout.about.data.attributes.aboutImg.data.attributes.formats
                  .large.url
              }
              alt=""
            />
          </div>
          <a
            className="instagramLink"
            href="https://www.instagram.com/into_my_skin/"
            target="_blank"
            rel="noreferrer"
          >
            INSTAGRAM&#8599;
          </a>
          <div className="emailLink">
            <button onClick={clickedCopyEmail}>COPY EMAIL&#8599;</button>
          </div>
          <a
            className="letterboxdLink"
            href="https://letterboxd.com/intomyskin/"
            target="_blank"
            rel="noreferrer"
          >
            LETTERBOXD&#8599;
          </a>
        </div>
      </div>
      <div className="headerArea">
        <h1>INTOMYSKIN</h1>
        <img
          src={headerimgSrc}
          loading="lazy"
          alt={
            dataHeaderImg.headerImage.data.attributes.headerImg.data.attributes
              .alternativeText
          }
        />
      </div>
      <img
        loading="lazy"
        src={
          dataAccentImg.accentImage.data.attributes.img.data[
            Math.floor(Math.random() * 3)
          ].attributes.formats.thumbnail.url
        }
        alt="accent"
        className="sticky-accent-image"
      />
      <div className="photoCardWrapper">
        {data.images.data.map((image) => {
          let paddingAmount = Math.floor(Math.random() * (700 - 100 + 1)) + 100;
          return (
            <div
              className="photoCard"
              key={image.id}
              style={{ "--padding-amount": paddingAmount + "px" }}
            >
              <img
                loading="lazy"
                onClick={() => triggerToggleAlbum(image.id)}
                src={image.attributes.img.data[0].attributes.formats.small.url}
                className="img-fluid"
                alt={image.attributes.img.data[0].attributes.alternativeText}
              />
              <div className="photoCardText">
                <button
                  className="openAlbumButton"
                  id={image.id}
                  onClick={() => triggerToggleAlbum(image.id)}
                >
                  {image.attributes.txt.toUpperCase()}
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <div
        onAnimationEnd={triggerToggleAlbum}
        className={toggleAlbum ? "visibleAlbumClass" : "hiddenAlbumClass"}
      >
        <a className="albumNavTitle" href="/">
          INTOMYSKIN
        </a>
        <button className="closeAlbumDivButton" onClick={triggerToggleAlbum}>
          x
        </button>
        <p className="leftArrow" onClick={clickedArrowButton}>
          ←
        </p>
        <p className="rightArrow" onClick={clickedArrowButton}>
          →
        </p>
        {data.images.data.map((image) => (
          <div className="albumPhotoCardWrapper" key={image.id}>
            {image.id === setClickedId ? (
              <div className="albumPhotoCard">
                <div className="albumPhotoCardText">
                  <p>{image.attributes.txt.toUpperCase()}</p>
                </div>
                <p className="cameraName">
                  {image.attributes.camera.toUpperCase()}
                </p>
                <img
                  loading="lazy"
                  src={
                    image.attributes.img.data[0].attributes.formats.medium.url
                  }
                  className="img-fluid albumPhotoMain"
                  alt={image.attributes.img.data[0].attributes.alternativeText}
                />
              </div>
            ) : null}

            <div data-key={image.id} className="carousel-container">
              <button
                className="carouselButton"
                id="prev-button"
                onClick={() => clickPrevButton(image.id)}
              >
                &lt;
              </button>
              <div className="carousel" data-key={image.id}>
                <div className="carousel-inner" data-key={image.id}>
                  {image.id === setClickedId
                    ? image.attributes.img.data.map((item, index) => (
                        <img
                          loading="lazy"
                          key={index}
                          onClick={goClickedImage}
                          className="thumbnail"
                          src={item.attributes.formats.medium.url}
                          alt=""
                        />
                      ))
                    : null}
                </div>
              </div>
              <button
                className="carouselButton"
                id="next-button"
                onClick={() => clickNextButton(image.id)}
              >
                &gt;
              </button>
            </div>
          </div>
        ))}
      </div>
      <footer>
        <div>
          <p>
            made with ♥ by
            <a
              href="https://dylanieogorman.com"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              D{" "}
            </a>
            and
            <a href="https://prduren.com" target="_blank" rel="noreferrer">
              {" "}
              PK{" "}
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;
